// apiEndpoints.js

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';

export const API_ENDPOINTS = {
  getUsers: `${API_BASE_URL}/v1/users`,
  getUser: (id) => `${API_BASE_URL}/v1/users/${id}`,
  registerUser: `${API_BASE_URL}/v1/auth/register`,
  login: `${API_BASE_URL}/v1/auth/login`,
  logout: `${API_BASE_URL}/v1/auth/logout`,
  renewAccessToken: `${API_BASE_URL}/v1/auth/refresh-tokens`,

  addPlace: `${API_BASE_URL}/v1/places/`,
  getPlaces: `${API_BASE_URL}/v1/places/`,
  getPlace: (id) => `${API_BASE_URL}/v1/places/${id}`,
  updatePlace: (id) => `${API_BASE_URL}/v1/places/${id}`,
  importPlaces: `${API_BASE_URL}/v1/places/import-places`,

  updatePlaceStatus: (id) => `${API_BASE_URL}/v1/places/live-status/${id}`,
  getPlaceStatus: (id) => `${API_BASE_URL}/v1/places/live-status/${id}`,
  uploadPlacePhoto: (id) => `${API_BASE_URL}/v1/places/${id}/photo`,
  uploadPhoto: (id) => `${API_BASE_URL}/v1/places/${id}/photo`,
  // Add more endpoints as needed
};
