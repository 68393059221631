import React from 'react';
import {Chip} from '@mui/material';

const StatusChip = ({ status }) => {
  let chipColor, chipLabel;

  switch (status) {
    case 'pending':
      chipColor = 'default';
      chipLabel = 'Pending';
      break;
    case 'published':
      chipColor = 'primary';
      chipLabel = 'Published';
      break;
    case 'rejected':
      chipColor = 'secondary';
      chipLabel = 'Rejected';
      break;
    default:
      chipColor = 'default';
      chipLabel = 'Unknown';
  }

  return <Chip color={chipColor} label={chipLabel} />;
};

export default StatusChip;