// apiService.js

import axios from 'axios';
import { API_ENDPOINTS } from './apiEndpoints';
import AuthManagementService from './auth'; // Import the getAccessToken function from authService

export const apiService = {
  getUsers: async () => {
    const response = await axios.get(API_ENDPOINTS.getUsers);
    return response.data;
  },

  getUser: async (id) => {
    const response = await axios.get(API_ENDPOINTS.getUser(id));
    return response.data;
  },

  registerUser: async (userData) => {
    const response = await axios.post(API_ENDPOINTS.registerUser, userData);
    if (response.status === 201) {
      localStorage.setItem('token', response.data.tokens.access.token);
      localStorage.setItem('refreshToken', response.data.tokens.refresh.token);
      localStorage.setItem('expires', response.data.tokens.access.expires);
      return response.data;
    }
  },

  login: async (userData) => {
    const response = await axios.post(API_ENDPOINTS.login, userData);
    if (response.status === 200) {
      localStorage.setItem('token', response.data.tokens.access.token);
      localStorage.setItem('refreshToken', response.data.tokens.refresh.token);
      localStorage.setItem('expires', response.data.tokens.access.expires);
      return response.data;
    }
  },

  logout: async () => {
    const response = await axios.post(API_ENDPOINTS.logout);
    if (response.status === 200) {
      localStorage.removeItem('token');
      return response.data;
    }
  },

  addPlace: async (placeData) => {
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.post(API_ENDPOINTS.addPlace, placeData, {
      headers,
    });
    return response.data;
  },

  savePlace: async (placeData) => {
    console.log('placeData', placeData);
    var url = API_ENDPOINTS.addPlace;
    var method = 'post';
    if (placeData._id) {
      url = API_ENDPOINTS.updatePlace(placeData._id);
      method = 'patch';
    }
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios[method](url, placeData, {
      headers,
    });
    return response.data;
  },

  getPlaces: async () => {
    const accessToken = await AuthManagementService.getAccessToken();
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.get(API_ENDPOINTS.getPlaces, { headers });
    return response.data;
  },

  getPlace: async (id) => {
    const response = await axios.get(API_ENDPOINTS.getPlace(id));
    return response.data;
  },

  importPlaces: async (file) => {
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'multipart/form-data',
    };
    const formData = new FormData();
    formData.append('file', file);
    const response = await axios.post(API_ENDPOINTS.importPlaces, formData, {
      headers,
    });
    return response.data;
  },

  renewAccessToken: async () => {
    const response = await axios.post(API_ENDPOINTS.renewAccessToken);
    if (response.status === 200) {
      localStorage.setItem('token', response.data.tokens.access.token);
      return response.data;
    }
  },

  updatePlaceStatus: async (id, field, status) => {
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.patch(
      API_ENDPOINTS.updatePlaceStatus(id),
      { [field]: status },
      {
        headers,
      }
    );
    return response.data;
  },

  getPlaceStatus: async (id) => {
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
    };
    const response = await axios.get(API_ENDPOINTS.updatePlaceStatus(id), {
      headers,
    });
    return response.data;
  },

  uploadPhoto: async (id, formData) => {
    const accessToken = await AuthManagementService.getAccessToken(); // Use the getAccessToken function
    const headers = {
      Authorization: 'Bearer ' + accessToken,
      'Content-Type': 'multipart/form-data',
    };
    const response = await axios.post(API_ENDPOINTS.uploadPhoto(id), formData, {
      headers,
    });
    return response.data;
  },
};
