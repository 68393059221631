import React from 'react';
import {
  Button,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { apiService } from 'services/apiServices';
import { useNavigate, useParams } from 'react-router';
import Dropzone from 'react-dropzone';
import StatusChip from './statusChip';
import ApproveButton from './approveButton';
import { gridSpacing } from 'store/constant';
import Status from './status';

const PlaceView = (props) => {
  const [placeDetails, setPlaceDetails] = React.useState({});

  const { id } = useParams();
  const navigate = useNavigate();

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.getPlace(id);
      setPlaceDetails(response.place);
    };
    fetchData();
  }, [id]);

  if (!placeDetails) {
    return <div>Loading...</div>;
  }

  const handleDrop = async (acceptedFiles) => {
    const file = acceptedFiles[0];
    const formData = new FormData();
    formData.append('file', file);
    const response = await apiService.uploadPlacePhoto(id, formData);
    console.log(response);
  };

  const uploadPlacePhoto = async (e) => {
    console.log('Uploading photo');
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    console.log('Uploading photo:', file);
    const response = await apiService.uploadPhoto(id, formData);
    console.log(response);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item lg={6} md={6} sm={8} xs={12}>
        <MainCard title={'Place Details'}>
          <h3>
            {placeDetails.name}
            <Button
              onClick={() => navigate('/places/edit/' + placeDetails._id)}
            >
              Edit
            </Button>
          </h3>{' '}
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>Photo:</TableCell>
                <TableCell>
                  <img
                    width="150"
                    alt='Place'
                    src={`${process.env.REACT_APP_API_BASE_URL}/${placeDetails.photo}`}
                  />
                  <input
                    accept="image/*"
                    hidden
                    onInput={uploadPlacePhoto}
                    onChange={uploadPlacePhoto}
                    id="raised-button-file"
                    type="file"
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="raised" component="span">
                      Upload
                    </Button>
                  </label>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Description:</TableCell>
                <TableCell>
                  <strong>{placeDetails.description}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Location:</TableCell>
                <TableCell>
                  <strong>{placeDetails.location}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>City:</TableCell>
                <TableCell>
                  <strong>{placeDetails.city}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address:</TableCell>
                <TableCell>
                  <strong>{placeDetails.address}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Country:</TableCell>
                <TableCell>
                  <strong>{placeDetails.country}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Place Type:</TableCell>
                <TableCell>
                  <strong>{placeDetails.type}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Rating:</TableCell>
                <TableCell>
                  <strong>{placeDetails.ratings}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Reviews:</TableCell>
                <TableCell>
                  <strong>{placeDetails.reviews}</strong>
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Tags:</TableCell>
                <TableCell>
                  <strong>{placeDetails.tags}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Popularity Score:</TableCell>
                <TableCell>
                  <strong>{placeDetails.popularityScore}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Max Capacity:</TableCell>
                <TableCell>
                  <strong>{placeDetails.maxCapacity}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Average Time Spend:</TableCell>
                <TableCell>
                  <strong>{placeDetails.averageTimeSpend}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Contact Person:</TableCell>
                <TableCell>
                  <strong>{placeDetails.contactPerson}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email:</TableCell>
                <TableCell>
                  <strong>{placeDetails.email}</strong>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Phone:</TableCell>
                <TableCell>
                  <strong>{placeDetails.phoneNumber}</strong>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </MainCard>
      </Grid>
      <Grid item lg={6} md={6} sm={4} xs={12}>
        <MainCard title={'Status'}>
          <CardContent>
            <Status id={id} title={'Live Status by Crowd'} field={'currentCrowd'} />
            <Status id={id} title={'Live Status by Owner'} field={'ownerReportedCrowd'} />

            <div style={{ marginTop: '20px' }}>
              <Typography variant="h6">Status</Typography>
              <StatusChip status={placeDetails.status} />{' '}
              <ApproveButton id={placeDetails._id} />
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Typography variant="h6">Photos</Typography>
              <img
                src={placeDetails.photos}
                alt={placeDetails.name}
                style={{ width: '100%', maxWidth: '500px' }}
              />
            </div>
            <Grid item xs={12} md={6}>
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Dropzone onDrop={handleDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()} className="dropzone">
                      <input {...getInputProps()} />
                      <p>Drag and drop files here, or click to select files</p>
                    </div>
                  )}
                </Dropzone>
              </div>
            </Grid>

            <Button variant="contained" color="primary">
              Add Photos
            </Button>
          </CardContent>
        </MainCard>
      </Grid>
    </Grid>
  );
};

export default PlaceView;
