
import { Typography } from "@mui/material";
import GroupsIcon from '@mui/icons-material/Groups';
import { Slider } from "@mui/material";
import { TextField } from "@mui/material";
import { useState, useEffect } from "react";
import { apiService } from 'services/apiServices';
import { Box } from "@mui/system";

export default function Status(props) {
  const { id, title, field } = props;
  const [placeStatus, setPlaceStatus] = useState({
    capacity: 50,
    live: 20,
    lastUpdated: new Date(),
  });

  const placeColor = (placeStatus) => {
    const percentage = (placeStatus.live * 100) / placeStatus.capacity;
    console.log('Percentage:', percentage);
    if (percentage < 50) {
      return 'success';
    } else if (percentage < 80) {
      return 'primary';
    } else {
      return 'error';
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.getPlaceStatus(id);
      setPlaceStatus({
        live: response.liveStatus?.[field] || 0,
        capacity: 50,
        lastUpdated: new Date(),
      });
    };
    fetchData();
  }, [id]);



  const changeSlider = (e, value) => {
    console.log('Slider:', value);
    setPlaceStatus({ ...placeStatus, live: value });
    // apiService.updatePlaceStatus(id, { live: value });
  };

  const onChangeCommitted = (e, value) => {
    console.log('Committed:', value);
    apiService.updatePlaceStatus(id, field, value );
  };

  return (

      <Box sx={{mb: 5}}>
        <Typography variant="h4">{title}</Typography>
        <GroupsIcon color={placeColor(placeStatus)} />
        {placeColor(placeStatus) !== 'success' ? (
          <GroupsIcon color={placeColor(placeStatus)} />
        ) : null}
        {placeColor(placeStatus) === 'error' ? (
          <GroupsIcon color={placeColor(placeStatus)} />
        ) : null}
        {placeColor(placeStatus) === 'error' ? (
          <GroupsIcon color={placeColor(placeStatus)} />
        ) : null}

        <Slider
          color={placeColor(placeStatus)}
          onChange={changeSlider}
          onChangeCommitted={onChangeCommitted}
          value={placeStatus.live}
          max={placeStatus.capacity}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <TextField
          label="Max Capacity"
          variant="outlined"
          value={placeStatus.capacity}
      />
    </Box>
  );
}