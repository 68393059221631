import axios from 'axios';
import { API_ENDPOINTS } from './apiEndpoints'; 

export const AuthManagementService = {
  async refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    if (!refreshToken) {
      return null;
    }
    console.log('Refreshing token', API_ENDPOINTS.renewAccessToken);
    const response = await axios.post(API_ENDPOINTS.renewAccessToken, { 
      refreshToken,
    });
    if (response.status === 200) {
      localStorage.setItem('token', response.data.tokens.access.token);
      localStorage.setItem('expires', response.data.tokens.access.expires);
      localStorage.setItem('refreshToken', response.data.tokens.refresh.token);
    }
  },

  // Function to check if the token is expired
  async isTokenExpired() {
    const expirationTime = localStorage.getItem('expires');
    const currentTime = Date.now();
    console.log('Expiration Time: ', expirationTime);
    console.log('Current Time: ', currentTime);
    return currentTime > expirationTime;
  },

  // Function to get the token
  async getAccessToken() {
    let token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    if (this.isTokenExpired(token)) {
      await this.refreshToken();
      token = localStorage.getItem('token');
    }
  
    return token;
  },

  async getUsers() {
    const token = await this.getAccessToken();
    console.log('Token: ', token);
    const response = await axios.get(API_ENDPOINTS.getUsers, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  async getUser(id) {
    const token = await this.getAccessToken();
    const response = await axios.get(API_ENDPOINTS.getUser(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },

  async updateUser(user) {
    const token = await this.getAccessToken();
    const {id, ...rest} = user;
    const response = await axios.patch(API_ENDPOINTS.getUser(id), rest, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  },
}

export default AuthManagementService;
