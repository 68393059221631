import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PlaceView from 'views/places/view';
import EditUser from 'views/users/edit';

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import('views/dashboard/Default'))
);

// sample page routing
const Places = Loadable(lazy(() => import('views/places')));
const AddPlace = Loadable(lazy(() => import('views/places/add-new')));
const EditPlace = Loadable(lazy(() => import('views/places/edit')));
const Visitors = Loadable(lazy(() => import('views/visitors')));

const Users = Loadable(lazy(() => import('views/users')));
const AddUSer = Loadable(lazy(() => import('views/users/add-new')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />,
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />,
        },
      ],
    },

    {
      path: 'places2',
      children: [
        {
          path: 'places',
          element: <Places />,
        },
        {
          path: 'places/add-new',
          element: <AddPlace />,
        },
        {
          path: 'places/:id',
          element: <PlaceView />,
        },
      ],
    },
    {
      path: 'visiters',
      children: [
        {
          path: 'visiters',
          element: <Visitors />,
        },
      ],
    },

    {
      path: 'places',
      element: <Places />,
    },
    {
      path: 'places/add-new',
      element: <AddPlace />,
    },

    {
      path: 'places/edit/:id',
      element: <EditPlace />,
    },
    {
      path: 'pages/users/add-new',
      element: <AddUSer />,
    },
    {
      path: 'pages/users',
      element: <Users />,
    },
    {
      path: 'pages/users/:id',
      element: <EditUser />,
    },
    {
      path: 'places/:id',
      element: <PlaceView />,
    },
    {
      path: 'visitors',
      element: <Visitors />,
    },
  ],
};

export default MainRoutes;
