import { Button, Grid, MenuItem, TextField } from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useEffect } from 'react';
import AuthManagementService from 'services/auth';
import MainCard from 'ui-component/cards/MainCard';
import { useParams } from 'react-router';

const EditUser = () => {
  const id = useParams().id;

  const [user, setUser] = useState({
    id: null,
    name: '',
    email: '',
    role: '',
    // Add more fields as needed
  });

  useEffect(() => {
    AuthManagementService.getUser(id).then((res) => {
      console.log(res);
      if (res.error) {
        return;
      }
      setUser(res.user);
    });
  }, []);

  const handleChange = (e) => {
    console.log('Change:', e.target.name, e.target.value);
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = (values) => {
    // Add your logic to update the user here
    AuthManagementService.updateUser(values);

    console.log('User updated:', values);
  };

  if (!user.id) {
    return <div>Loading...</div>;
  }

  return (
    <MainCard title="Edit User">
      <h2>Edit User</h2>

      <Formik
        onSubmit={handleSubmit}
        initialValues={{ ...user }}
        handleChange={handleChange}
      >
        {({ handleChange, handleSubmit, values }) => (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                type="text"
                name="name"
                sx={{ mr: 2, width: '200' }}
                value={values.name}
                onChange={handleChange}
              />
              <TextField
                label="Email"
                type="email"
                name="email"
                sx={{ mr: 2, width: 300 }}
                value={values.email}
                onChange={handleChange}
              />
              <TextField
                label="Role"
                type="text"
                name="role"
                sx={{ mr: 2, width: 200 }}
                value={values.role}
                select
                onChange={handleChange}
              >
                <MenuItem value={'admin'}>Admin</MenuItem>
                <MenuItem value={'user'}>Super Admin</MenuItem>
                <MenuItem value={'content'}>Content</MenuItem>
                <MenuItem value={'owner'}>Venue Owner</MenuItem>
                <MenuItem value={'user'}>User</MenuItem>
              </TextField>
              <br />
              {/* Add more input fields for other user properties */}
            </Grid>
            <Grid item xs={12}>
              <Button onClick={handleSubmit} variant="contained" type="submit">
                Save
              </Button>{' '}
              <Button variant="contained" color="secondary">
                Cancel
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </MainCard>
  );
};

export default EditUser;
